// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-articles-jsx": () => import("./../../../src/pages/articles.jsx" /* webpackChunkName: "component---src-pages-articles-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-research-jsx": () => import("./../../../src/pages/research.jsx" /* webpackChunkName: "component---src-pages-research-jsx" */),
  "component---src-pages-sign-in-jsx": () => import("./../../../src/pages/sign-in.jsx" /* webpackChunkName: "component---src-pages-sign-in-jsx" */),
  "component---src-pages-tags-jsx": () => import("./../../../src/pages/tags.jsx" /* webpackChunkName: "component---src-pages-tags-jsx" */),
  "component---src-pages-tutorials-jsx": () => import("./../../../src/pages/tutorials.jsx" /* webpackChunkName: "component---src-pages-tutorials-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-single-tag-jsx": () => import("./../../../src/templates/singleTag.jsx" /* webpackChunkName: "component---src-templates-single-tag-jsx" */)
}

